import { graphql, Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import products from '../assets/images/products.jpg';
import NavPages from '../components/NavPages';
import SEO from '../components/SEO';

const ProductsStyled = styled.div`
  width: 100%;
  .products-header {
    background-image: linear-gradient(
        to bottom,
        var(--whiteTrans),
        var(--whiteTrans)
      ),
      url(${products});
  }
  .page-description,
  .series,
  .disciplines,
  .collections {
    margin: 2rem 0;
    h4 {
      text-align: center;
      padding: 2rem;
    }
    .wrap {
      /*display: grid;*/
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 2rem;
      align-items: center;
      justify-items; center;
      text-align: center;
      a {
        text-decoration: none;
      }
      > * {
        flex-grow: 1;
        flex-basis: calc(100% / 3 - 2rem);
      }
    }
    @media(max-width:1023px) {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }
  .wrap div {
    text-align: center;
    background: var(--backgroundCard);
    border: 1px solid var(--borderColor);
    padding: 12rem 2rem;
    border-radius: 2rem;
    transition: var(--speed);
    box-shadow: var(--boxShadow);
    &:hover {
      cursor: pointer;
    }
    h3 {
      color: var(--textColor);
      @media(max-width: 1023px){
        font-size: 2.5rem;
      }
    }
    @media(max-width: 1023px){
      padding: 4rem 2rem;
    }
  }
`;

export default function ProductsPage({
  data: { products, disciplines, series, collections },
}) {
  const disciplinesLength = disciplines.nodes.length;
  const seriesLength = series.nodes.length;
  const collectionsLength = collections.nodes.length;
  return (
    <>
      <SEO
        title="Explore Bont Cycling Products"
        description="All Bont Cycling shoes are made to the highest quality standards to ensure the best fit and performance, day in day out. Explore our products by discipline, series or collection."
      />
      <ProductsStyled>
        <div className="wrapper">
          <section className="page-header products-header">
            <div>
              <h2>Products</h2>
            </div>
          </section>
          <section className="page-description products-description">
            <p>
              All Bont Cycling shoes are made to the highest quality standards
              to ensure the best fit and performance, day in day out. Explore
              our products by discipline, series or collection.
            </p>
          </section>
          <section className="disciplines">
            <h4>Disciplines</h4>
            <div className="wrap" data-children-count={disciplinesLength}>
              {disciplines.nodes.map((item) => (
                <Link to={`/disciplines/${item.slug.current}`}>
                  <div>
                    <h3>{item.title}</h3>
                  </div>
                </Link>
              ))}
            </div>
          </section>
          <section className="series">
            <h4>Series</h4>
            <div className="wrap" data-children-count={seriesLength}>
              {series.nodes.map((item) => (
                <Link to={`/series/${item.slug.current}`}>
                  <div>
                    <h3>{item.title}</h3>
                  </div>
                </Link>
              ))}
            </div>
          </section>
          <section className="collections">
            <h4>Collections</h4>
            <div className="wrap" data-children-count={collectionsLength}>
              {collections.nodes.map((item) => (
                <Link to={`/collections/${item.slug.current}`}>
                  <div>
                    <h3>{item.title}</h3>
                  </div>
                </Link>
              ))}
            </div>
          </section>
        </div>
        <NavPages />
      </ProductsStyled>
    </>
  );
}

export const query = graphql`
  query {
    products: allSanityProducts {
      nodes {
        slug {
          current
        }
        title
        id
        description
        productImage {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    disciplines: allSanityDisciplines(
      filter: {
        slug: {
          current: { nin: ["discontinued", "spare-parts", "multi", "aero"] }
        }
      }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        id
        slug {
          current
        }
        title
      }
    }
    series: allSanitySeries(
      filter: {
        slug: { current: { nin: ["discontinued", "spare-parts", "multi"] } }
      }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        id
        slug {
          current
        }
        title
      }
    }
    collections: allSanityCollections(
      filter: { slug: { current: { nin: ["discontinued"] } } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        id
        slug {
          current
        }
        title
      }
    }
  }
`;
